import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'libphonenumber-js';

const pattern = /(\+1)\s(\d{3})\s(\d{3})\s(\d{4})/;
const replaceFormat = '$1 ($2) $3-$4';

@Pipe({ name: 'otPhone' })
export class PhonePipe implements PipeTransform {
  public transform(givenPhoneNumber: any): string {
    return givenPhoneNumber
      ? format(givenPhoneNumber, 'US', 'International').replace(pattern, replaceFormat)
      : '' ;
  }
}
