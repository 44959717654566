/* tslint:disable */

export const namePattern = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/;
export const fullNamePattern = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+ [a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/;
export const phoneMask = ['+', '1', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const USssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const USssnPattern = /\d{3}-\d{2}-\d{4}/;
export const lastSsnDigitsMask = [/\d/, /\d/, /\d/, /\d/];
export const lastSsnDigitsPatternt = /\d{4}/;
export const phonePattern = /\(\d{3}\)\s\d{3}-\d{4}/;
export const birthDayPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
export const birthDayMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
// eslint-disable-next-line no-useless-escape
export const emailPattrern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const stepsStrings = ['step-one', 'step-two'];
export const ssnPattern = /^\d{3}-\d{2}-\d{4}$|^\d{3}-\d{3}-\d{3}$/;
export const welcomeSteps = ['step-one', 'step-two', 'oops', 'thank-you', 'sorry', 'coming-soon', 'department-error', 'position-error'];
export const loadingAgreementMessage = 'Please wait while your Agreement is being processed...';

export const GenderModel  = {
  Male: '0',
  Female: '1'
};
/* tslint:enable */

export enum RecruitVerificationStatusEnum {
  DoNotHire = 0,
  HasActiveAccount = 1,
  NeedToCreateAccount = 2,
  Error = 3,
  AgreementSigned = 4,
  MissingPosition = 5,
  WorkedInDifferentDepartment = 6
}

export enum PEActivationStatusEnum {
  DoNotHire = 0,
  Active = 1,
  Inactive = 2
}

export const housingOptions = [
  {
    text: 'Single Housing ($125 + $16 for utilities weekly)',
    value: 0
  },
  {
    text: 'Single Private Housing ($200 + $16 for utilities weekly)',
    value: 1
  },
  {
    text: 'Family Housing ($250 + $25 for utilizes weekly)',
    value: 2
  },
  {
    text: 'No Vivint Housing Needed',
    value: 3
  },
];
