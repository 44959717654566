/* tslint:disable */
import { namePattern } from '../agreement/constants';

export const CanadaPostalCodeMask = [/[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]/, /\d/, /[abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ]/, ' ', /\d/, /[abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ]/, /\d/];
// eslint-disable-next-line no-useless-escape
export const PostalCodePatternCombined = /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ][ ][0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ][0-9]|\d{5}([ \-]\d{4})?/;
export const USAPostalCodeMask = Array(5).fill(/\d/);
export const dateOfBirthPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
export const dateOfBirthMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const ssnMaskCanada = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const ssnMaskUSA = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const ssnPatternCombined = /\d{3}-\d{3}-\d{3}|\d{3}-\d{2}-\d{4}/;
export const numberPattern = /(^\d{1,7}$)|(^\d{1,7}\.{1}\d{1,2}$)/;
export const maxInputLength = 255;

export interface ICotrolConfig {
  mask: any[];
  placeholder: string;
  name: string;
  errorMessage: string;
}

export interface ICountrySpecificControlConfig {
  us: ICotrolConfig;
  canada: ICotrolConfig;
}

const ssnConfig: ICountrySpecificControlConfig = {
  us: {
    mask: ssnMaskUSA,
    placeholder: 'XXX-XX-XXXX',
    name: 'SSN (Social Security Number)',
    errorMessage: 'Please, enter correct Social Security Number',
  },
  canada: {
    mask: ssnMaskCanada,
    placeholder: 'XXX-XXX-XXX',
    name: 'SIN (Social Insurance Number)',
    errorMessage: 'Please, enter correct Social Insurance Number',
  }
};
const postalCodeConfig: ICountrySpecificControlConfig = {
  us: {
    mask: USAPostalCodeMask,
    placeholder: '00000',
    name: 'ZIP',
    errorMessage: 'Please enter a valid ZIP code e.g., 12345',
  },
  canada: {
    mask: CanadaPostalCodeMask,
    placeholder: '000 000',
    name: 'Postal Code',
    errorMessage: 'Please enter a valid Postal Code code. Use the following format: X1X 1X1',
  }
};

export const countrySpecificConfig = {
  ssn: ssnConfig,
  postalCode: postalCodeConfig
};

export const configTypes =  {
    ssn: 'ssn',
    postalCode: 'postalCode'
};
/* tslint:enable */

export enum PhoneTypeEnum {
  mobile = 1
}

export const countriesNames = {
  canada: 'Canada',
  us: 'United States of America'
};

export const swipeMoveDirection = {
  right: 'prev',
  left: 'next'
};

export enum DepartmentsEnum {
  Unknown = 0,
  Sales = 1,
  Tech = 2
}

export const getDepartmentName = (isTech: boolean): string => {
  return (isTech ? DepartmentsEnum[DepartmentsEnum.Tech] : DepartmentsEnum[DepartmentsEnum.Sales]).toLowerCase();
};

const digitPattern = /\d/;
const numberAllowedSymbolPattern = /\d|\./;

function getPureValueLength(value: any, pattern: any) {
  let length: number = 0;
  for (const char of value) {
    if (char.match(pattern)) {
      length++;
    }
  }
  return length;
}

function getOnlyDigitsMask (value: any, pattern: any, maxLength: any) {
  const mask: any[] = [];

  const pureValueLength: number = getPureValueLength(value, pattern);

  mask.length = pureValueLength > maxLength ? maxLength : pureValueLength;
  mask.fill(pattern);

  return mask;
}

function getNameMask (value: any, maxLength: any) {
  const mask: any[] = [];
  const pureValueLength: number = getPureValueLength(value, namePattern);

  mask.length = pureValueLength > maxLength ? maxLength : pureValueLength;
  mask.fill(namePattern);

  return mask;
}


export const onlyDigitsMask = (maxLength = maxInputLength) =>
  (value: any) => getOnlyDigitsMask(value, digitPattern, maxLength);

export const onlyNumberMask = (maxLength = maxInputLength) =>
  (value: any) => getOnlyDigitsMask(value, numberAllowedSymbolPattern, maxLength);

export const nameMask = (value: any) => getNameMask(value, maxInputLength);
