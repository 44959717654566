import _extends from 'babel-runtime/helpers/extends';
import _classCallCheck from 'babel-runtime/helpers/classCallCheck';
import _createClass from 'babel-runtime/helpers/createClass';
import _defineProperty from 'babel-runtime/helpers/defineProperty';
import _Symbol$iterator from 'babel-runtime/core-js/symbol/iterator';
import parse from './parse';
import Metadata from './metadata';

import { PLUS_CHARS, VALID_PUNCTUATION, VALID_DIGITS,
// OPENING_BRACKETS,
WHITESPACE, create_extension_pattern } from './common';

// Copy-pasted from `./parse.js`.
var VALID_PHONE_NUMBER = '[' + PLUS_CHARS + ']{0,1}' + '(?:' + '[' + VALID_PUNCTUATION + ']*' + '[' + VALID_DIGITS + ']' + '){3,}' + '[' + VALID_PUNCTUATION + VALID_DIGITS + ']*';

var EXTN_PATTERNS_FOR_PARSING = create_extension_pattern('parsing');

var WHITESPACE_IN_THE_BEGINNING_PATTERN = new RegExp('^[' + WHITESPACE + ']+');
var WHITESPACE_IN_THE_END_PATTERN = new RegExp('[' + WHITESPACE + ']+$');

// // Regular expression for getting opening brackets for a valid number
// // found using `PHONE_NUMBER_START_PATTERN` for prepending those brackets to the number.
// const BEFORE_NUMBER_DIGITS_PUNCTUATION = new RegExp('[' + OPENING_BRACKETS + ']+' + '[' + WHITESPACE + ']*' + '$')

export default function findPhoneNumbers(arg_1, arg_2, arg_3, arg_4) {
	var _sort_out_arguments = sort_out_arguments(arg_1, arg_2, arg_3, arg_4),
	    text = _sort_out_arguments.text,
	    options = _sort_out_arguments.options,
	    metadata = _sort_out_arguments.metadata;

	var search = new PhoneNumberSearch(text, options, metadata.metadata);

	var phones = [];

	while (search.hasNext()) {
		phones.push(search.next());
	}

	return phones;
}

/**
 * @return ES6 `for ... of` iterator.
 */
export function searchPhoneNumbers(arg_1, arg_2, arg_3, arg_4) {
	var _sort_out_arguments2 = sort_out_arguments(arg_1, arg_2, arg_3, arg_4),
	    text = _sort_out_arguments2.text,
	    options = _sort_out_arguments2.options,
	    metadata = _sort_out_arguments2.metadata;

	var search = new PhoneNumberSearch(text, options, metadata.metadata);

	return _defineProperty({}, _Symbol$iterator, function () {
		return {
			next: function next() {
				if (search.hasNext()) {
					return {
						done: false,
						value: search.next()
					};
				}
				return {
					done: true
				};
			}
		};
	});
}

/**
 * Extracts a parseable phone number including any opening brackets, etc.
 * @param  {string} text - Input.
 * @return {object} `{ ?number, ?startsAt, ?endsAt }`.
 */
export var PhoneNumberSearch = function () {
	function PhoneNumberSearch(text) {
		var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
		var metadata = arguments[2];

		_classCallCheck(this, PhoneNumberSearch);

		this.state = 'NOT_READY';

		this.text = text;
		this.options = options;
		this.metadata = metadata;

		this.regexp = new RegExp(VALID_PHONE_NUMBER +
		// Phone number extensions
		'(?:' + EXTN_PATTERNS_FOR_PARSING + ')?', 'ig');

		// this.searching_from = 0
	}
	// Iteration tristate.


	_createClass(PhoneNumberSearch, [{
		key: 'find',
		value: function find() {
			var matches = this.regexp.exec(this.text);

			if (!matches) {
				return;
			}

			var number = matches[0];
			var startsAt = matches.index;

			number = number.replace(WHITESPACE_IN_THE_BEGINNING_PATTERN, '');
			startsAt += matches[0].length - number.length;
			number = number.replace(WHITESPACE_IN_THE_END_PATTERN, '');

			// // Prepend any opening brackets left behind by the
			// // `PHONE_NUMBER_START_PATTERN` regexp.
			// const text_before_number = text.slice(this.searching_from, startsAt)
			// const full_number_starts_at = text_before_number.search(BEFORE_NUMBER_DIGITS_PUNCTUATION)
			// if (full_number_starts_at >= 0)
			// {
			// 	number   = text_before_number.slice(full_number_starts_at) + number
			// 	startsAt = full_number_starts_at
			// }
			//
			// this.searching_from = matches.lastIndex

			var result = parse(number, { defaultCountry: this.options.defaultCountry }, this.metadata);

			if (result.phone) {
				result.startsAt = startsAt;
				result.endsAt = startsAt + number.length;

				return result;
			}

			// Tail recursion.
			// Try the next one if this one is not a valid phone number.
			return this.find();
		}
	}, {
		key: 'hasNext',
		value: function hasNext() {
			if (this.state === 'NOT_READY') {
				this.last_match = this.find();

				if (this.last_match) {
					this.state = 'READY';
				} else {
					this.state = 'DONE';
				}
			}

			return this.state === 'READY';
		}
	}, {
		key: 'next',
		value: function next() {
			// Check the state and find the next match as a side-effect if necessary.
			if (!this.hasNext()) {
				throw new Error('No next element');
			}

			// Don't retain that memory any longer than necessary.
			var result = this.last_match;
			this.last_match = null;
			this.state = 'NOT_READY';
			return result;
		}
	}]);

	return PhoneNumberSearch;
}();

export function sort_out_arguments(arg_1, arg_2, arg_3, arg_4) {
	var text = void 0;
	var options = void 0;
	var metadata = void 0;

	// If the phone number is passed as a string.
	// `parse('88005553535', ...)`.
	if (typeof arg_1 === 'string') {
		text = arg_1;
	} else throw new TypeError('A text for parsing must be a string.');

	// If "default country" argument is being passed
	// then move it to `options`.
	// `findNumbers('88005553535', 'RU', [options], metadata)`.
	if (typeof arg_2 === 'string') {
		if (arg_4) {
			options = _extends({ defaultCountry: arg_2 }, arg_3);
			metadata = arg_4;
		} else {
			options = { defaultCountry: arg_2 };
			metadata = arg_3;
		}
	}
	// No "default country" argument is being passed.
	// Only international phone numbers are passed.
	// `findNumbers('+78005553535', [options], metadata)`.
	else {
			if (arg_3) {
				options = arg_2;
				metadata = arg_3;
			} else {
				metadata = arg_2;
			}
		}

	// Metadata is required.
	if (!metadata || !metadata.countries) {
		throw new Error('Metadata is required');
	}

	if (!options) {
		options = {};
	}

	// // Apply default options.
	// if (options)
	// {
	// 	options = { ...default_options, ...options }
	// }
	// else
	// {
	// 	options = default_options
	// }

	return { text: text, options: options, metadata: new Metadata(metadata) };
}
